.colforo{
    /* border: 1px 1px 0px 0px solid solid #f8f0f0; */
    border-right: 1px solid #d8d8d8;
}
.postbox{
    box-shadow: 5px 5px 20px rgba(191, 209, 228, 0.489);
    padding: 2%;
    padding-top: 1%;
    border-radius: 10px;
  
        margin-bottom:0.1%;
    background-color: rgba(242, 249, 255, 0.361);
}
.postformnewpost{
    box-shadow: 5px 5px 20px rgb(191, 209, 228);
    border-radius: 10px;
    padding: 3%;
    background-color: rgb(251, 254, 255);
}
.buttonforo{
    background-color: var(--naranja);
    border: 0px;
    color: white;
    font-size: 15px;
    padding: 5px;
    margin: 2px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border: 0.5px solid #696969;
    border-radius: 10px;
}
.titlenewpost{
    width: 100%;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: 0px;
    padding: 14px;
    transition: min-height var(--transition);
}
.butonsforopost{
    font-size: 14px;
}
.forocard{
    height: 15vh;
    width: 100%;
    margin: 1%;
    border-radius: 10px;
    position: relative;
    box-shadow: 5px 5px 20px rgb(191, 209, 228);
}
.forotosect{
    height: 40px;
}
.titlepost{
    font-size: 18px;
    padding-left: 3%;
    font-weight: 500;
    background-color: var(--azulgris);
    color:white
 
    
}
.msgpost{
  font-size: 17px;  
  font-family: "Nunito", sans-serif;
}

.bottomforocard{
    position: absolute;
    bottom: 0;
}
.linkrouter {
  display: flex;
  
  width: 49%;
  text-decoration: none; /* Opcional: evita el subrayado */
  color: inherit; /* Mantiene el color del texto */
}
.NameCardForo{
    font-size: 14px;
}
.linkminimized{
    width: 20%;
}
.ForoUserCard{
    display:flex;
    margin:10px
}
.forotitle{
    font-size: 28px;
    height: fit-content;
    padding: 5px;
    border-radius: 10px 10px 0px 0px;
}
.fc-General{
    background-color: rgb(98, 0, 255);
}

.fc-Académico{
    background-color: rgb(60, 127, 60);
}
.imgforo{
    max-width: 90px;
    width: 70%;
    margin: 0 auto;
}

.fc-Casos{
    background-color: rgba(255, 0, 0, 0.671);
}

.fc-vincularse{
    background-color: rgba(0, 81, 255, 0.693);
}

.fc-Soporte{
    background-color: rgba(255, 166, 0, 0.647);
}
.postsubbox{
 display: flex;
 width: 100%;
}
.postsubboxleft{
    width: 30%;
}
.postsubboxright{
    width: 70%;
}

@media only screen and (max-width: 900px) {
.postsubbox{
    display: block;
}
.postsubboxleft{
    width: 100%;
}
.postsubboxright{
    width: 100%;
}
}
@media only screen and (max-width: 600px) {
.forocard{
    height: 31.5vh;

    margin:1%
}
.imgforo{width:50px;}
.NameCardForo{
    font-size: 12px;
    color: #696969;
    font-family: Arial, Helvetica, sans-serif;
}
.msgpost{
    font-size: 15px;
    margin-left: 15px;
}
.butonsforopost{
    font-size: 10px;
}
.forotosect{
    height: 40px;
}
.ForoUserCard{
    display:flex;
    margin:0px;
    margin-left:15px
}

.linkrouter{
    width: 50%;
}
.ForoCard{
    font-size: 12px;
}

}
