


.sliding-banner {
  overflow: hidden;
  width: 100%;
  
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espaciado entre filas */
  /* clip-path: polygon(0 0, 100% 0, 96% 100%, 0% 100%); */
  
   -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}



.sliding-banner > .sliding-item {
  transition: transform 20s ease; /* Usar transform y reducir el tiempo */
  transform: translateX(0); /* Iniciar animación */
}


.rowslide{
  display: flex;
  gap: 20px; /* Espaciado entre elementos */
   animation: slide-left 20s linear infinite;    
}

.sliding-item {
  min-width: 150px; /* Ancho mínimo de los elementos */
  /* background-color: #007bff; */
  background-color: var(--celeste);
  filter: brightness(1.1);
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Evita que los elementos se redimensionen */
}

/* Animación para el deslizamiento continuo */
  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

@media (max-width: 600px) {
      .sliding-banner {
    padding: 5px 0; /* Reducir padding en móviles */
  }
  .sliding-item {
    font-size: 13px;
    min-width: 90px;
  
  }
  .sliding-banner > .sliding-item {
    transition: transform 0.3s ease-in-out; /* Más rápido en móvil */
  }
    
}


