.FAQ article{
    width: 90%;
    max-width: fit-content;
}
.FAQ .fuenteh2{
    margin: 0%;
    padding: 0%;
}
.FAQ .fuente2{
    font-size: 16px;
}
.FAQ .fuenteh2{
    font-size: 22px;
}
.FAQ h5{
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}
.cardhelp{
    border: 0px;
    color: var(--azuloscuro);
    background-color: var(--turqueza);
}
.helpcard, .acordhelp{
    border:0px;
}
.cardhelp .btn{
color:white
}
@media only screen and (max-width: 768px) {
    .FAQ .fuenteh2{
        margin: 0 auto;
        padding: 1%;
        font-size: 20px;
    }

}
