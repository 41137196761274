@import url("https://fonts.googleapis.com/css/Open+Sans");

/* .form label {
   display: block; 
  width: 35px;
} */
input[type="checkbox"] {
  
  background-color: #fff;
  margin: 0;
  margin-right: 2px;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}
.rounded-pill-custom{
  border-radius: 10px;
}
.form-control + .form-control {
  margin-top: 1em;
}
  .spannohorario{
 font-size: 13.5px;
  color:#ff7575b7;
  color:#3d5a80
}
/* .checkmark {
  top: 0;
  left: 0;
  margin-right: 10px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
} */

.display-block {
  display: block;
  margin-bottom: 10px;
}

.display-inline-block {
  display: inline-block;
  margin-left: 10px;
}

/* .label-item {
  cursor: pointer;
} */

.checkmark {
  display: none; /* Ocultar input real */
}

/* Personalizar checkbox */
input[type="checkbox"] + label {
  position: relative;
  padding-left: 20px; /* Espacio para el círculo */
  cursor: pointer;
  line-height: 25px;
}

input[type="checkbox"] + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 15%;
  background-color: #dcdfe5;
}

input[type="checkbox"]:checked + label::before {
  background-color: #2196F3;
  border:1px white solid;

}

.react-datepicker-time__input{
  cursor: text;
}


.display-block {
  display: flex;
  margin-bottom: 6px;
}

.display-inline-block {
  display: inline-block;
}

.repeated {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.label-calendar{
  font-size: 14px;
  text-align: left;
  margin:0;
}
.h1-name {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.logo {
  max-width: 100%;
  margin: 0px;
  padding: 0px;
}
.boton-ovalado21 {
  padding: 0;
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 3%;
  border: none;
  border-radius: 65px;
  background-color: #0a3979;
  color: #fff;
  width: 6em; /* Establece el ancho deseado */
  height: 2.6em; /* Establece la altura deseada */
  font-size: 16px;
  cursor: pointer;
  box-shadow: 2px 2px 2px #c8ddeb;
}
.boton-ovalado22 {
  padding: 0;
  margin-top: 10px;
  margin-left: 0%;
  margin-right: 40%;
  border: none;
  border-radius: 3px;
  background-color: #645fa3;
  color: #fff;
  width: 6em; /* Establece el ancho deseado */
  height: 2.6em; /* Establece la altura deseada */
  font-size: 14px;
  cursor: pointer;
  box-shadow: 2px 2px 2px #c8ddeb;
}
.articlewksp {
  color: rgba(241, 248, 250, 0.562);
  margin-left: 7%;
  margin-top: 3%;
}
.titlecalendar{
  color: var(--naranja);
  font-weight: 500;
  font-size: 16px;
}
.container-services-calendar{
  margin: 0 auto;
  width: 95%;
}

.wspsection {
  margin-left: 3%;
  margin-right: 3%;
}
.wskedit {
  margin: 0px;
  padding: 0px;
  display: flex;
}

.wskedit:hover .fa-solid.fa-pen {
  display: block;
}
.wksph1 {
  color: #2a3747;
  margin: 3%;
  text-shadow: 1px 1px 1px rgb(230, 228, 228);
}
.wksph2 {
  color: #2a3747;
  margin: 3%;
  font-size: 100%;
  text-shadow: 1px 1px 1px rgb(230, 228, 228);
}
.wkslabel {
  margin: 0px;
  margin-left: 5%;
  padding: 0px;
  text-align: left;
}
.wkslabel p {
  margin-left: 10%;
  margin-top: 3px;
  font-size: 85%;
  margin-bottom: 2px;
  margin-right: 10px;
  padding: 0px;
}
.wksdat {
  margin: 0px;
  margin-left: 15%;
  padding: 0px;
  text-align: left;
}

.wksdat p {
  margin-left: 0px;
  margin-top: 3px;
  font-size: 85%;
  margin-bottom: 2px;
  margin-right: 0;
  padding: 0px;
}

.wksppat {
  margin-bottom: 3%;
  max-width: 100%;
  height: auto;

  background-color: rgba(255, 255, 255, 0.562);
  margin-left: 6%;
  margin-right: 6%;
  border: 0.3px solid #f8f0f0;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(241, 240, 240, 0.5);
}
.wksppat article {
  font-family: "Open Sans", sans-serif;
  color: #2a3747;
  font-weight: bold;
  font-size: 95%;
  margin-top: 3%;
  margin-bottom: 2%;
  margin-left: 3%;
}
.wksppat2 {
  margin: 0%;
  padding: 0%;
  display: flex;
}
.wksppat:hover {
  color: #fff;
  background-color: #4a76b3;
  box-shadow: inset 0 0 5px rgba(241, 240, 240, 0.5);
}

.boton-ovalado22:hover {
  background-color: #4a76b3;
  box-shadow: 1px 1px 1px rgb(230, 228, 228);
}

.boton-ovalado21:hover {
  background-color: #4a76b3;
  box-shadow: 1px 1px 1px rgb(230, 228, 228);
}
.bodywksp {
  max-width: 100%;
  /* background-color: rgb(248, 255, 255);*/
  /* background-color: rgb(251, 252, 236);*/
  background-color: rgb(104, 120, 173);
  font-family: "Montserrat", helvetica, arial, sans-serif;
  height: 90vh;
  display: "flex";
  align-items: "center";
  overflow: hidden;
}
.subnav2 {
  max-width: 100%;
  display: flex;

  justify-content: center;
  align-items: space-between;
}

.wkspc {
  width: 80%;
  max-width: 80%;
  background-color: #ffffff;
  margin: 0px;
  margin-bottom: 15px;
  border: 0.3px solid #f8f0f0;
  /* Radio de los bordes para hacerlos redondeados */
  height: 100vh;
  box-shadow: 1px 1px 1px rgb(230, 228, 228);
}

.wkspace {
  margin: 0px;
  height: 100vh;
  width: 90%;
  max-width: 90%;

  border-image: linear-gradient(
      to right,
      rgb(161, 185, 236),
      rgb(104, 120, 173)
    )
    1;
  transition: border-image 0.5s ease-in-out;

  background-color: rgb(128, 143, 190);
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  justify-content: center;
}
.wkspace:hover {
  border-image: linear-gradient(
      to right,
      rgb(161, 185, 236),
      rgb(104, 120, 173)
    )
    10;
}

.containerprofilep {
  display: flex;
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #afd6d4;
  max-height: 100%;
  margin: 0px;
}

.sidebar-leftp:hover .fa-solid.fa-pen {
  display: block;
}

.sidebar-rightc {
  max-width: 370px;
  text-align: center;
  margin: "0px";
  padding: 10px;
  height: auto;
  color: #fff;
  /* Radio de los bordes para hacerlos redondeados */
  overflow: hidden;
  border: 0px solid #0a3979;
  background-color: #0a3979;

  /* Otros estilos que deseas aplicar fuera de la consulta de medios */
}

.sidebar-rightp {
  width: 30%;
  border-radius: 0px 75px 75px 0px;
}

@media only screen and (max-width: 768px) {
  .containerprofile {
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .h1-name {
    position: relative;
  }
  .logo {
    display: none;
  }

  .sidebar-left,
  .sidebar-right,
  .wksp {
    width: 95%;
    max-width: 355px;
    margin-bottom: 15px;
    overflow: scroll;
  }
  table {
    display: none;
  }


}

/* Estilo para la imagen dentro del contenedor */
.imagen-redperf img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para cubrir completamente el contenedor */
}
@media only screen and (max-width: 768px) {
  .spannohorario{
  font-size: 10px;
  color:#3d5a80
}
}
