.izqwk{
    padding-top: 5%;
    height:100vh;
    margin-right: 1%;
    box-shadow: 0 0 8px rgba(146, 146, 146, 0.2);
    background: var(--azulgris);

    border-radius: .25rem 0 0 .25rem;
  

  }
  .perfilcentralwk{
    background: var(--white);  
    height: 100vh;
    margin-top: 2px;
    padding-left: 10px;
    overflow-y: scroll;
  }
  .perfilcontenedorwk{
    width: 100%;
    margin: 0 auto;
  }
  .Postwksp{
    display: block;
    padding: 2%;
  }
  .posttext{
    color: black;
  }
  .actionbutton{
    justify-content: right;
    text-align: right;
  }
  .imgcontainer{
    align-items: center;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }
  .optionshome{
    
    justify-content: left;
    margin: 3%;
    padding-left: 5px;
    display: flex;
  }
  .optionshome:hover{
    background-color: #30a0c6;
  }
  .fawks{
    color: white;
  }
  .txtwk{
    color: white;
    margin: 0;
    margin-left: 5px;
  }
  .boxwk{
    border-radius: var(--border-radius);
    box-shadow: 0 0 8px rgba(146, 146, 146, 0.2);
    background-color: #fff;
    margin: 1%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    padding: 2%;
  
  }
  .wksp_section{
    width: 80%;
    padding: 0%;
    margin: 0 auto;
  }
  .headerhome{
    color: black;
    padding: 2%;
    margin: 0;
  }
  .buttonmobile{
    display: none;
  }
  .linkwkspc{
    display: flex;
  }
  .accout-edit{
    width:50%;
    margin:0 auto;

  }
  @media only screen and (max-width: 991px) {
      .izqwk{
        display: block;
   
      height: fit-content;
  } 
   .optionshome{
    display: none;
  }
    .buttonmobile{
    display: block;
    text-align: right;
    justify-content: right;
    margin-right: 10px;
    font-size: 16   px;
  }
    .expandwk{
    display: flex;
  }
    .accout-edit{
    width: 90%;
  }
 

  }

  @media only screen and (max-width: 465px) {
    .izqwk{
        display: block;
   
      height: fit-content;
  } 


  }  