@import url(https://fonts.googleapis.com/css?family=Roboto:wght@400;700&display=swap);
.dots-us-p{ 
    width:19%;
     margin-bottom:3%; 
    position: absolute;
    left: 3%;
    top:38%  
  }
  .basicp{
    list-style-type: none;
    display: inline;
  }
  .profiledatalist{
    display: inline-block;
  }
  .labelp .perfilp{
    display: inline-block;
    
    
  }


  .h5persona-profile{
    font-size: 15.5px;
  
  }

  .izq{
    padding-top: 12%;
    height:100%;
    margin-right: 1%;
    box-shadow: 0 0 8px rgba(146, 146, 146, 0.2);
    background: var(--azulgris);

    border-radius: .25rem 0 0 .25rem;
  

  }
  .cardlft{
    height: 100vh;
    background: var(--celeste);
    border: 0px;
  }
  .img-circular-p{

   overflow: hidden;   
   border-radius: 50%; 
   position: absolute;
   left: 22%;/* Establece el radio para crear el marco redondo */ 
    top: 10%;

    border: 30px solid ; 

  }
  .img-circular-us-p{
    border-radius: 50%;
    max-width: 100%;

    
    max-height: 100%;
    
  }
  .desc-personal{
    margin-bottom: 3.5%;
  }
  .lapizperfil{
    color: #ffffff;
  }
  .perfilcontenedor{
    width: 70%;
    margin: 0 auto;
  }

  .perfilp{
    font-size: 13.5px;
    width: 100%;
    margin: 0%;
    color: #727272;
    font-weight: 500;
  }
  .personalcontendor{
    width: 90%;
    margin: 0 auto;
  }
  .pref{text-align: center;
  }
  .redh5{
    color: var(--azulnegro);
    font-size: 20px;
    margin:0 auto;
  }
  .titlepubliccalendar{
       color: var(--azulnegro);
       margin-top: 20px;
    font-size: 18px;
  }
  .perfilcentral{
    background: var(--white);  
    height: 100%;
  }
  .notshowmobile{
    display: block;
  }

   .navlist{
display:flex;
justify-content:space-between;
 width:100%;
  margin:0 auto;
   height:45px; 
   
   background-color:var(--celeste);
  }
  .perfilcontenedor{
    width: 70%;
    margin: 0 auto;
    
  }

  .perfilp{
    font-size: 13.5px;
    width: 100%;
    margin: 0%;
    color: #727272;
    font-weight: 500;
  }
  .personalcontendor{
    width: 85%;
    margin: 0 auto;
  }

  .perfilcentral{
    background: var(--white);  
    height: 100%;
    position: relative;
    min-height: 95vh;
  }

  .perfildiv{
      width: 75%;
      margin: 0 auto;
      background: white;
      height: 100vh ;
      background-color: var(--celeste);
  }
  .gender_selector{
    min-width: 100%;
  }
  .variable-container {
    display: flex;
    flex-wrap: wrap;
  justify-content: center;
  }
  .variable-container2 {
    display: flex;
    flex-wrap: wrap;
  justify-content: center;
  width: 350px;
  margin:0 auto;
  }
  .variable-container3{
    max-width: 95%;
  }
  
  .variable-item {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    flex: 0 1 auto; /* Permite que los elementos se ajusten automáticamente */
  }

  .expandir{
    margin-left: 80%;
    display: none;
    position: relative;
    top:-5%;
    justify-content: right;
    align-content: right;
    text-align: right;
    font-size: 12px;
  }
  .notshow{
    display: none;
  }
  .show{
    display: inline;
  }
  .show2{
    display: block;
  }
  .selectperfil{
    display: block;
  }
  .labelp{
    font-weight: "bolder";
    margin-right: "5%";
  }
  .agendar{
    width: 150px;
    align-self: center;
    justify-self: center;
  margin: 0 auto;
  padding: 3px;
  height:33px;
  position: relative;
  border-radius: 18px;
  font-size: 10px;
  left: 0%;
  margin-right: 2%;
  margin-top:2%;
  box-shadow: unset;
  }
  .textosprofilecontainer{
    margin:0 auto;
    width: 70%;
  }
  .agendar b{
    font-size: 10px;
  }
  .profile_esp{
    display: flex;
 
    max-width: 85%;
  }
  .indications{
    font-size: 13px;
  }

  .boton-rectangulo-toggle1{
    padding: 2px;
    background-color: #8299bc;
    height: 18px;
    color: white;
    font-size: 8.5px;
    align-content: center; 
    border-radius: 3px 0px 0px 3px;
    border:0px;
  }
  .boton-rectangulo-toggle2{
    padding: 2px;
    background-color: #8299bc;
    height: 18px;
    color: white;
    font-size: 8.5px;
    align-content: center; 
    border-radius: 0px 3px 3px 0px;
    border:0px;
  }
  .mostrarprop{
    background-color: #58ade5;
  }
  .tglbutton{
    box-sizing: border-box;
  }
  .selectpersonal{
    font-size: 12px;
  }
  .encabezadospersonal {
    display: flex;
    justify-content: space-between;
    max-width: 335px;
  }

  .textnavprofile{
    color: var(--azulnegro);
    font-weight: 500;
  }
  .espprof{
    /* background:#1aaedd; */
    /* background: #31e9da; */
    background: #00AB9F;

    color: #ffffff; /* Blanco */
    width: 100px;
    height:32px ;
   text-align: center; /* Centra horizontalmente */
    align-content: center; 
    font-size: 12px;
    font-weight: 500;
    margin-inline-start: auto;
    line-height: 100%;
  }
  /* .infoprof .imgframe{
    width: 70%;
    margin: 0 auto;   
}   */
  .espprof2{
    color: var(--azulnegro); /* Blanco */
    width: 100px;
    height:32px ;
    align-items: center;
   text-align: center; /* Centra horizontalmente */
    align-content: center; 
    font-size: 12px;
    font-weight: 500;
    margin-inline-start: auto;
    line-height: 100%;
  }

  .infomobile2{
    display: none;
    position: absolute;
    top:55%;
    left:35%;
  }
  .boxprofile{
    border-radius: var(--border-radius);
    box-shadow: 0 0 8px rgba(146, 146, 146, 0.2);
    background-color: #fff;
    margin-left: 0.5%;
    margin-right: 0.5%;
    padding: 2%;
    margin-top: 0.5%;
  
  }
  .infolaptop{
    display: block;
    margin-top: 3%;
    text-align: left;
  }
  .infomobile{
    display: none;
  }

  .titletext{
    color:var(--gristexto2);
    margin-top: 6%;
  }
  .titletext1{
    color:var(--gristexto2);
  }
  .sobremi{
    font-family:'Nunito',sans-serif;
    line-height: 1.85;
    color:var(--gristexto2)
  }
  .card{
    word-wrap: normal;
  }
  .vision{
    font-family:'Nunito',sans-serif;
    line-height: 1.85;
    color: var(--gristexto2);
  }

  .fa-o{
    font-size: 8px;
  }
  .especial{
    font-family:'Nunito',sans-serif;
    line-height: 1.85;
    color:var(--gristexto2)
  }
  .multi-select {
    width: 100%;
    height: fit-content;
    padding: 5px;
    box-sizing: border-box;
  }
    .namemobile{
    display: none;
  }
   .namemobile2{
    display: none;
  }
  .namedesktop{
    display: block;
  }

  
  .multi-select option {
    padding-left: 25px;
    position: relative;
  }
  
  .multi-select option::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007BFF;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .multi-select option:checked::before {
    background-color: #28A745; /* Cambia el color si es necesario */

  }

  .costos p{
    color: rgb(68, 68, 68);
    font-weight: 600;
  }
.divbotoneservice{
  display:flex;
  max-width:50% ;
  justify-content:space-between ;
  margin:0 auto;
  margin-bottom: 10px;

}
  .perfilp{
    color: white;
  }
  .lfh{
    color: white;

    font-weight: 500;
    justify-content: center;
    margin-bottom: 0;
    font-size: 25px;
    font-style: bold;
    font-family: 'Nunito', sans-serif;  
   /* text-shadow: 2px 0 2px  #bfbfc1ad; */
  } 
  .profesion1{
    text-align: center;

    color: #ffffff;
    padding: 0%;
    margin: 0;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 20px;
  }
  .profesion2{

    color: #ffffff;

    text-align: center;
    margin: 0;
    font-weight: 500;
    margin-bottom: 8%;
    font-size: 16px;

  }
  .calendarcontainer{
    margin: 0 auto;
  }
  .caja-texto-profile {
    max-height: 300px ;
    height: 275px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    margin: auto;
    border: 0px;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 14px;
    transition: min-height var(--transition);
    max-height: 200px;
    resize: none;
    white-space: pre-line;
  }
.navprofile{
  width: 100%;
}
.selectedpestana{
  color: var(--celesteclaro);
}
.linkestudio{
  margin-top: 16px;
}
.linkestudio:hover{
  color: #007fab;
}
.headers-academic{
  margin-bottom: 10px;
  
}
.p-buttonsedit{
  position: absolute;
  top:15%;
  left:85%;
  font-size: 12px;
  z-index: 1;
}
.penprofiledit{
  position: relative;
  top:10%;
margin-bottom: 0%;
margin: 0%;
}
.sectionacademicprofile{
  position: relative;
  top: -5%;
  padding: 0;
  margin: 0;
}

.fa-pen{
  color:var(--naranja)
}
.profilebutton{
  width: 150px;
  height: 30px;
  padding: 0px;
  font-size: 10px;
  text-align: center;
  margin-top: 3%;
  
  margin-inline: 3px;
  margin-left:0px;
  background-color:var(--naranja);
}
.profilebutton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.pref-atencion{
  font-size: 11px;
  text-align: center;
  align-content: center;
  width: 100px;
  height: 35px;
  margin:6px
}
.pref-atencion-selected{
  font-size: 11px;
  text-align: center;
  align-content: center;
  width: 100px;
  height: 35px;
  margin:6px;
  color:var(--azulnegro);
  background: var(--celeste);
}
.underlinered{
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3.5px;
    
  text-decoration-color: var(--naranja);
}
.servicebox{
  position: relative;
  margin: 0 auto;
  margin-bottom: 8px;
  min-width: 100%;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(100, 157, 175, 0.384);
  background-color: var(--white);
  color: var(--white);
  /* background-color: #8e8e8e0c; */
  padding: 10px;  
  padding-top:15px
  
}
.calendarservicebox{
  background-color: var(--celesteclaro);
}
.servicebox p{color:var(--azulnegro)}
.serviceboxform{
  text-align: left;
  margin: 0 auto;
  margin-bottom: 8px;
  max-width: 100%;
  width: 100%;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(100, 157, 175, 0.384);
  background-color: #8e8e8e0c;
  padding: 6px;
  padding-left: 8px;  
}

.service-title{
  display:flex;
  justify-content:space-between;
   margin:0 auto;
    max-width:70%;
}

.postselection{
font-weight: 600;
height: 40px;
  /* background:white; */
  /* background:#def7ffb8; */
  background:#f9fdfff1;
  /* background:#f9fdffb8; */
  /* background: var(--celesteclaro); */
  border: 1.5px solid;
  box-shadow: 0 0 20px rgba(100, 157, 175, 0.384);
  /* box-shadow: 0 0 50px rgba(255, 171, 86, 0.554); */
  border-color: var(--azulgris);
   cursor: default;
   box-shadow: 0px;
   color: var(--azulnegro);
   font-family: 'Nunito';
}

.inputacademicnae{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 55%;
}
.inputacademicyr{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 15%;
}
.inputservicename{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 55%;
  margin-left: 0px;
}
.inputservicenumber{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 20%;
}
.inputservicenumber2{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 12%;
}


.inputservicename{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 55%;
  margin-left: 0px;
}
.inputservicenumber{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 20%;
}
.inputservicenumber2{
  margin:6px;
  border-width: 0px 0px 2px 0px;
  width: 12%;
}
   .costos p{
    color: rgb(68, 68, 68);
    font-weight: 400;
  } 

.pstudyindx{
  align-items: center;
  margin: 0%;
  margin-left: 5%;
  text-align: left;
}
.indxdown{
  align-items: center;
  margin-left: 8%;
}

@media (max-width: 1368px) {

  .perfilcontenedor{
    width: 88%;
  }
}

@media (max-width: 1240px) {

  .perfilcontenedor{
    width: 95%;
  }
}
@media (max-width: 1100px) {

  .perfilcontenedor{
    width: 98%;
  }
}


@media (max-width: 991px) {
  /* .izq .card-text{
    position: relative;
    top: -10%;
  } */
  .lapizperfil{
    color: #727272;
  }

  .img-circular-us-p{
    border-radius: 50%;
    border: 2px solid #fff; 
}
  .navlist{
    /* background: var(--turqueza2);  */
    background: var(--celeste);
    width: 97%;
    border-radius: 7px;
   }
  .izq{
    padding-top: 0;
    margin: 1%;
   border: 0px;
    height: 300px;
    background-color: white;
} 
.infolaptop{
  display: none;
}
.infomobile{
  display: none;

}
.infomobile2{
  display: block;

}
  .infoprof .imgframe{
  
    max-width: 30%;
    margin: 2%;
    flex:1;
    top:0;
    left:-2%
}  
.perfilp{
  margin:0%;
  font-size: 13px;
  padding: 0%;
  text-align: left;
  color:  #5b5b5b;
  margin-left: 2.5%;
  font-family: 'Roboto',sans-serif;
  font-weight: 500;
  margin: 0 auto;
  justify-content: left;
}
.lfh{
  color: #8e8e8e;
  flex:2;
}
.lfh{
  text-align: left;
  font-size: 25px;
  width: 100%;
  font-weight:950;
  justify-content: left;
  margin: 0%;
  padding-top: 12%;
  font-family: 'Roboto', sans-serif;    } 
  
.profesion1{
  position: relative;
  font-family: 'Nunito',sans-serif;
  max-width: 60%;
  top:55%;
  left:33%;
  color:white;
  font-size: 19px;
  font-weight: 600 ;
  text-align: left;
  }
  .profesion2{
    position: relative;
    font-family: 'Nunito',sans-serif;
    top:45%;
    max-width: 60%;
    left:33%;
    color:white;
    font-size: 18px;
    font-weight: 600 ;
    text-align: left;
    }
    .izq .card-body{
      padding: 2%;
      border: 0px;
      height: 250px;
      max-height: 110px;
        /* background: linear-gradient( #2665ca,#1f55a6) !important;   */
        background: var(--azulgris);
      overflow: visible;
    }
    
    .landprf{
      display: flex;
      margin-top: -15%;
      
  }
  
}
@media (max-width: 885px) {
  .izq{height: 270px;}
  .namemobile{
    display: block;
  }
  .namedesktop{
    display: none;
  }
  .boxprofile{
    margin-top: 2%;
  }

}
@media (max-width: 775px) {
  .lfh{
    padding-top: 80px;
  }
}
@media (max-width: 520px) {
  .lfh{
    padding-top: 75px;
  }
  .textosprofilecontainer{
    width: 100%;
  }
}
@media (max-width:495) {
    .lfh{

      font-size: 21px;
     } 
     .divbotoneservice{
      display:block
     }
}

@media only screen and (max-width: 465px) {
  .izq{
    margin: 2.5%;
   border: 0px;
    height: 120px;
} 
  .agendarnavbar{
    height: 130%;
    width: 25%;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    border: 2px solid var(--celesteclaro);
  background: rgb(152,193,216);
background: radial-gradient(circle,var(--celeste) 65%, var(--celesteclaro) 85%);
  }

.profile_esp{
  margin: 0 auto;
 }


    .body{
        line-height: 0%;

        
    }

    .izq .card-body{
      padding: 2%;
      border: 0px;
      height: 70px;

      max-height: 70px;
        /* background: linear-gradient( #2665ca,#1f55a6) !important;   */
      background: var(--azulgris);
      overflow: visible;
    }

    .pstudyindx{
 font-size: 14px;
 margin-left: 4px;
 font-weight: 300;
    }
.profilebutton{
   width: 135px;
  height: 30px;
  padding: 0px;
  font-size: 10px;
  text-align: center;
  margin-top: 3%;
  background-color:var(--naranja);
}

    .perfilcontenedor{
      width: 100%;
    }
    .perfilcentral{
      /* max-height: 80vh;
      overflow: scroll; */
      background-color: #fcfdff;
    }
    .perfildiv{
      width: 100%;
      margin: 0 auto;
      background: white;
      height: 100vh ;
  }
  .servicebox{
    max-width: 98%;
    width: 98%;
  }
    .pref{
        margin-top: 1.5%; 
        margin-bottom: 0.7em;
        font-size: 1.1rem;
        color: var(--azulnegro);
        font-weight: 500; 
        max-width: 90;
    }
    .titletext{
      margin-top: 5px;
    }
    .infoprof .imgframe{
      min-width: 35%;
      margin: 2%;
      flex:1;
      top:16px;
      
  }  

    .terceracol{
      display: none;
    }
    .perfilcentral .card-body{
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 1.5%;
    }
    
.service-title{
  max-width: 94%;
}
    .dots-us-p{
        top:32%
    }
    .expandir {
        display: block;
        color: #ffb013;
    }

    .lfh{
      color: #8e8e8e;
      flex:2;
  }
  .lfh{
      text-align: left;
      font-size: 110%;
      width: 100%;
      font-weight:950;
      justify-content: left;
      margin: 0%;
      padding-top: 18%;
      font-family: 'Roboto', sans-serif;    } 

/* .izq .card-body{
  padding: 2%;
  border: 0px;
  height: 50px;
  max-height: 70px;
    background: linear-gradient( #2665ca,#1f55a6) !important;  
  overflow: visible;
} */
/* .col-lg-6 .card-body{
padding: 3%;
} */
   .costos p{
    color: rgb(68, 68, 68);
    font-weight: 400;
  }
.landprf{
    display: flex;
    margin-top: -20%;
    
}
.no-gutters a:hover{
    color: #ffb013;
}
/* .izq:hover {
    height: 350px;
} */
.especial{
  
  line-height: 140%;
  margin-top: 8px;
  height: auto; /* La altura se ajusta automáticamente al contenido */
  transition: height 0.8s ease; 
    overflow-y: hidden; /* Permitir el desbordamiento vertical con desplazamiento */
    /* mask-image:linear-gradient(to bottom, white , white,white,white,white,white,transparent); */
  

}
     .divbotoneservice{
      display:block
     }
.vision{
  
  line-height: 140%;
  margin-top: 8px;
  height: auto; /* La altura se ajusta automáticamente al contenido */
  transition: height 0.8s ease; 
     overflow-y: hidden; /* Permitir el desbordamiento vertical con desplazamiento */
     /* mask-image:linear-gradient(to bottom, white , white,white,white,white,white,transparent); */
 
    
}
    
.sobremi{
    
    height: auto; /* La altura se ajusta automáticamente al contenido */
    transition: height 0.8s ease; 
    line-height: 140%;
    margin-top: 8px;
    overflow-y: hidden; /* Permitir el desbordamiento vertical con desplazamiento */
     /* mask-image:linear-gradient(to bottom, white , white,white,white,white,white,transparent); */
    
}
.clarity{
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><linearGradient id='gradiente' gradientTransform='rotate(45)'><stop offset='0%' stop-color='black' /><stop offset='100%' stop-color='transparent' /></linearGradient><rect x='0' y='0' width='280%' height='220%' rx='8px' fill='url(%23gradiente)' /></svg>");
  mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><linearGradient id='gradiente' gradientTransform='rotate(45)'><stop offset='0%' stop-color='black' /><stop offset='100%' stop-color='transparent' /></linearGradient><rect x='0' y='0' width='280%' height='220%' rx='8px' fill='url(%23gradiente)' /></svg>");
 text-overflow: ellipsis;
}
.expanded{
    max-height: 1000px;
    mask-image: none;
    transition: height 0.8s ease; 
}
.expanded2{
    min-height: 230px;
    mask-image: none;
    transition: height 0.8s ease; 
}



.title-sectionprofile{
  font-size: 16px;
}



.desc-personal .card-title{
    margin-bottom: 6px;
    font-size: 1.15rem;
    color: rgb(87, 86, 86);
    font-weight: 500;
}
.desc-personal p{
  
    font-size: 15px;
}
.desc-personal{
  margin-bottom: 5%;
}
.infolaptop{
  display: none;
}
.infomobile{

  display: none;
  position: static;
}
.infomobile2{
  display: block;
  top: 47%;
  left:40%;
  max-width:200px;
  overflow: none;

}
.schedu{
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .agendar{
    font-size: 12px;
    height: fit-content;
    padding: 3%;
    

  }
  .profesion1, .profesion2{
    top:30%;
font-size: 13.5px;
left: 39%;
  }
  .notshowmobile{
    display: none;
  }
  .linkestudio{
    margin-top: 5%;
    font-size: 12px;
  }

}
@media only screen and (max-width: 432px) {
.namemobile{display:none}
.profilebutton{
   width: 125px;
  height: 30px;
  padding: 0px;
  font-size: 10px;
  
  text-align: center;
  margin-top: 3%;
  background-color:var(--naranja);
}
.namemobile2{display:block}
}

/* @media only screen and (max-width: 377px) {
  .expanded2{
min-height: 250px;
  }
  .infomobile2{
    top:40%
  } */
/* } */
@media only screen and (max-width: 385px) {
  .lfh{font-size: 16px;}
  .expanded2{
min-height: 195px;
  }
  .infomobile2{
    top:49%
  }
      .profesion1, .profesion2 {
        font-size: 12px;
        left: 39%;
        top: 32%;
    }
    .profilebutton{
   width: 125px;
  height: 30px;
  padding: 0px;
  font-size: 9px;
  text-align: center;
  margin-top: 3%;
  background-color:var(--naranja);
}
    /* .servicebox{
      min-width: 100%;
    } */
}