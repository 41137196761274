.catalog__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
}
.borderlinecard{
  width: 94%;
  min-height: 400px;
  margin:3%;
  border-radius: 15px;
  border:1px solid var(--celeste);
}
.dots-us-catalog{ 
    width:15%;
     margin-bottom:3%; 
    position: absolute;
    left: 10%;
    top:68%  
  }
  .pcardcataloginfo{
    text-align: center;
    color:#747373;
    margin: 1.5px;
    padding: 0px;
  }
  .catalogblock{
    display:block
  }
  .linkcard{
    text-decoration: underline;
    position: absolute;
    top:90%;
    left:40%;
    color: var(--celeste);
    font-weight: bold;

  }
  .linkcard:visited{
color: var(--celeste);
  }
  .linkcard p{
    color: var(--celeste);
    font-weight: bold;
  }
  
  .img-circular-us-catalog {
    width: 65%; /* Puedes ajustar el tamaño según tus necesidades */
  max-width: 180px;
    height: auto;
   overflow: hidden;   
   border-radius: 50%; 
   position: absolute;
   left: 28%;/* Establece el radio para crear el marco redondo */ 
    top: 10%;
  }  
  .catalog-text{
    position:relative; 
   top:6em; 
   padding-top: 8px;
  }

.catalog__card {
    width: min(100%, 800px);
    height: auto;
    background-color: #fff;
    border: 1px solid #747373;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    box-shadow: 5px 2px 2px grey;
}
.imgframe2{
  margin:0px;
  padding: 0px;
  width: fit-content;
  position: relative;
  top:5;
  min-width: 100px;
  min-height: 100px;
  width:inherit;
} 
.catalog__card2 {

    border: 0px solid #747373;
    border-radius: 15px;
    min-height: 0.3vh;
    padding: 0%;
    box-shadow: 5px 5px 20px rgb(191, 209, 228);
}
.psico-catalog{
    padding:1%;
    padding-left: 0px;
    padding-right: 0px;
}


.catalog__fig {
    width: 50%;
    padding: 0.5rem;
    display: flex;
    align-self: center;
}

.catalog__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: min(80%, 400px);
}

.catalog__lfh {
    padding-top: 0px;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
     color:var(--azulnegro)
}

.catalog__profile_esp {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    font-size: 12px;
    color: var(--azuloscuro);
    position: absolute;
    top:25%;
    
    margin: 0 auto;

}
.cardcatalogcontainer{
  width: 95%;
    padding-left: 1%;
  padding-right: 1%;
}
.otro .row-cols-2,.otro .row-cols-md-3{
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 auto;
  width: 97%;
}

.catalog__espprof{
    margin-inline-start: unset;
    color: var(--azulnegro); /* Blanco */
    width: fit-content;
    font-size: 14px;
    margin:2%;
    margin-top: 1px;
    margin-right: 1px;
    text-align: left;
    background: var(--celesteclaro);
    box-shadow: 1px 1px 8px rgb(196, 224, 255);
    border-radius: 8px;
}
@media (max-width: 1450px) {
.catalog-text{
  top:4.3em
}}
@media (max-width: 1280px) {
.catalog-text{
  top:3.4em
}
  .catalog__card2 {
       min-height: 50vh;
       max-height: 50vh;
    
}
.borderlinecard{

  min-height: 48vh;
  max-height: 48vh;

}
}

@media (max-width: 1195px) {
.catalog-text{
  top:3.5em
}
}

@media (max-width: 1150px) {
            .dots-us-catalog{
  top:50%;

}
.catalog-text{
  top:2.8em
}
  .catalog__card2 {
       min-height: 48vh;
       max-height: 48vh;
    
}
.borderlinecard{

  min-height: 46vh;
  max-height: 46vh;

}
}

@media (max-width: 1150px) {

  .catalog__card2 {
       min-height: 53.5vh;
       max-height: 53.5vh;
    
}
.borderlinecard{

  min-height: 51.5vh;
  max-height: 51.5vh;

}
}

@media (max-width: 990px) {
            .dots-us-catalog{
  top:55%;
}
.catalog-text{
  top:5em
}
  .catalog__card2 {
       min-height: 46vh;
       max-height: 46vh;
    
}
.borderlinecard{

  min-height: 44vh;
  max-height: 44vh;

}
}
@media (max-width: 960px) {
            .dots-us-catalog{
  top:55%;
}
.catalog-text{
  top:5em
}
  .catalog__card2 {
       min-height: 50vh;
       max-height: 50vh;
    
}
.borderlinecard{

  min-height: 48vh;
  max-height: 48vh;

}
}
@media (max-width: 915px) {
            .dots-us-catalog{
  top:55%;
}
.catalog-text{
  top:5em
}
  .catalog__card2 {
       min-height: 53vh;
       max-height: 53vh;
    
}
.borderlinecard{

  min-height: 51vh;
  max-height: 51vh;

}
}
@media (max-width: 860px) {
.catalog-text{
  top:4em
}
  .catalog__card2 {
       min-height: 52vh;
       max-height: 52vh;
    
}
.borderlinecard{

  min-height: 50vh;
  max-height: 50vh;

}
}
@media (max-width: 810px) {
.catalog-text{
  top:5.5em
}
  .catalog__card2 {
       min-height: 54vh;
       max-height: 54vh;
    
}
.borderlinecard{

  min-height: 52vh;
  max-height: 52vh;

}
}

@media (max-width: 650px) {
      .dots-us-catalog{
  top:60%;
  max-width: 22%;
  left:10%
}

.catalog__lfh {
    padding-top: 0px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
    font-size: 18px;
    color:var(--azulnegro)
}
.pcardcataloginfo{
  font-size:12.5px ;
  margin:2px;
  font-weight: 500;
  font-family:'Poppins'
}
.catalog-text{
    position:relative; 
   top:4.2em; 
   padding: 1%;
  }
  .catalog__card2 {
       min-height: 46vh;
       max-height: 46vh;
    
}
.borderlinecard{
  width: 90%;
  min-height: 42vh;
  max-height: 42vh;
  margin:5%;
  border-radius: 15px;
  border:1px solid var(--celeste);
}
.linkcard{
  top:95%;
  left: 32%;
  
}
.sectioncatalog{
  margin-bottom: 100px;
}
  }
  @media (max-width: 540px) {
              .dots-us-catalog{
  top:50%;
  max-width: 22%;
  left:10%
}
    .catalog-text{
    
   top:3em; 
   padding: 1%;
  }
  }

   @media (max-width: 520px) {
   



  .catalog__card2 {
       min-height: 41vh;
       max-height: 41vh;
    
}
.borderlinecard{
  
  min-height: 38vh;
  max-height: 38vh;
}

  }
       @media (max-width: 495px) {

    .catalog-text{
    
   top:2.5em; 
   padding: 1%;
  }}
     @media (max-width: 430px) {

    .catalog-text{
    
   top:2em; 
   padding: 1%;
  }

  .catalog__card2 {
       min-height: 41vh;
       max-height: 41vh;
    
}
.borderlinecard{
  
  min-height: 39vh;
  max-height: 39vh;
}

  }


  @media (max-width: 400px) {
             .dots-us-catalog{
  top:40%;
  max-width: 22%;
  left:10%
}
   .catalog-text{
    
   top:1.7em; 
   padding: 1%;
  }

  .catalog__card2 {
       min-height: 45vh;
       max-height: 45vh;
    
}
.borderlinecard{
  width: 90%;
  min-height: 43vh;
  max-height: 43vh;
  margin:5%;
  border-radius: 15px;
  border:1px solid var(--celeste);
}

  }
   @media (max-width: 380px) {

   .catalog-text{
    
   top:1em; 
   padding: 1%;
  }

  .catalog__card2 {
       min-height: 45vh;
       max-height: 45vh;
    
}
.borderlinecard{
  width: 90%;
  min-height: 43vh;
  max-height: 43vh;
  margin:5%;
  border-radius: 15px;
  border:1px solid var(--celeste);
}

  }
