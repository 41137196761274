.calendar-container {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
  font-family: Arial, sans-serif;
  min-width: 600px;
  overflow-x: scroll;
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  border-radius: 0px;
  font-weight: bold;
  color: #fdfdfd;
  font-weight: 600;
  margin-left: 35px;
  background-color: var(--azulgris);
}
.showactivity{
  display: block;
}
.notshowactivity{
  display: none;
}
.calendar-header-cell {
  padding: 10px;
  
}
.contenedorkeyhora{
  height:50px ;
  font-size: 12px;
  width: 35px;
  
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  grid-auto-rows: 50px;
}

.calendar-cell {
  border: 0.3px solid #cee1ff;
  padding: 5px;
  height: 100%;
  position: relative;
   background: linear-gradient(
    #f4f8fe,
    #f4f8fe,
    #fdfdfd,
    #fdfdfd,
    #fdfdfd,
    #f4f8fe,
    #f4f8fe
  ) !important;
}

.cell-time1 {
    padding: 0;
  margin: 0;
  font-size: 12px;
z-index: 2;
  min-width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
}
.cell-time2 {
  padding: 0;
  margin: 0;
  z-index: 2;
  font-size: 12px;

  position: absolute;
  top: 50%;
   min-width: 100%;
  left: 0%;
}


.cell-activity {
  background-color: #16b4b463;
  color: rgb(255, 252, 252);
  position: absolute;
  z-index: 5;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  padding: 5px;
  width: 90%;
  box-shadow:0 0 50px 50px 0 rgba(126, 167, 214, 0.089);
  
}
.calendarweekcontainer{
  margin:0 auto;
  width: 80%;
}

@media (max-width: 500px) {
  .calendarweekcontainer{
    width: 98%;
    overflow-x: scroll;
  }
  /* .contenedorkeyhora2{
    display: block;
    position: fixed;
    z-index: 10;
    left: 3px;
  }
  .calendar-body{
    position:relative;
    left: 35px
  } */
}