
.div-selector-form{
    width: 80%;
}
.inputagendamiento{
   font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 12px;
  overflow: scroll;
  margin: 0 auto;
  width: 80%;
}
.confirmationformsend{width: 75%;}
@media (max-width: 500px) {
.inputagendamiento{
width: 96%;
}
.div-selector-form{
    width:96%
}
.confirmationformsend{
    width: 100%;
}
}
