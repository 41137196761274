/* Estilos generales de la barra de navegación */
.navbar {
    display: flex;
    padding-top: 10px;
    justify-content: space-between ;
    align-items: center;
    width: 100%;
    /* background-color: #f7f7f7; */
    /* background-color: #ffffff ; */
    color: rgb(255, 255, 255);
    max-width: 100%;   
    padding-right: 5%;
  }
  .navbar-brand {
    font-size: 1.5em;
    width: 35%; 
  }
  .navbar_profiles {
    display: flex;
    padding-top: 0px;
    justify-content: space-between ;
    align-items: center;
    width: 100%;
    /* background-color: #f7f7f7; */
    background-color: #ffffff ;
    color: rgb(255, 255, 255);
    max-width: 100%;   
  }
  .navbar-brand_profiles {
    font-size: 1.5em;
    width: 35%; 
  }
  .imghead_profiles {
    margin-left: 0px;
    width: 40%; /* Establece el ancho deseado */
    height: auto; /* Establece la altura deseada */
    }

  .fanav {
  
  
    width: 20%;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    font-size:100px;
    }
    
   


  
  /* Estilos para dispositivos móviles */
  .mobile-menu-button {
    display: none; /* Ocultar por defecto en pantallas grandes */
    font-size: 1.5em;
   
    cursor: pointer;
  }
  
  /* Mostrar la lista de navegación en dispositivos móviles */
  .nav-list {
    margin-left: 10%;
    padding: 0%;
    list-style: none;
    display: flex;
  }
  
  
  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px; /* Bordes redondeados */
    overflow: hidden; /* Oculta contenido que se sale de los bordes redondeados */
    align-content: 'center ';/* Centra el texto del submenú */
    margin: 0px; /* Elimina los márgenes */
    padding: 0px; /* Elimina el relleno */
    list-style: none; /* Elimina la lista puntada */
    z-index: 9 ;  
  }
  .submenu-item {
    max-width: 100%;
    padding: 5px;
   
    font-family: 'Phetsarath', sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: var(--azulpetroleo);
    display: block;
    margin: 0px; /* Elimina los márgenes */
    width: 100%;
  }
  .item-lista {
    font-family: 'Phetsarath', sans-serif;
    font-size: 18px;
    background-color: var(--azulgris);
    border: 2px solid var(--azulgris);
    
    /* color: var(--azuloscuro); */
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    display: inline-block;
    margin-right: 1px;
    padding: 0;
    cursor: pointer;
    text-shadow: 2px 2px 4px #a0a0a0;
    border-radius: 15px;
    padding: 3px 15px;
    height: 35px;

  }
  .item-lista:hover{
    color: white;
    background-color: var(--azuloscuro);
  }
  .item-lista:hover .submenu {
    display: block;
  }
  .deshorizontal {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    margin: 0 1px;
    padding: 3px 15px;
    /* margin: 0 15px;   Ajusta el margen según sea necesario */
    color:white;
}
.deshorizontal:visited{
  color:var(--azuloscuro)
}



  .submenu-item:hover {
    color: white;
    background-color:rgb(109, 166, 204);
  }
  .submenu-item:hover a{
    color: white;
    background-color:rgb(109, 166, 204);
  }
  form {
    /*max-width: 1000px;*/
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center; /* Para centrar el contenido del formulario */
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .navbarlog{
    font-size: 45px;
    font-weight: 500;
    color: var(--azulpetroleo);
    font-family: 'Paytone One', serif;
  }
  
  /* Estilos específicos para dispositivos móviles */
  @media (max-width: 1050px) {
    .mobile-menu-button {
      display: flex;
      margin-left: 20%;
      margin-right: 5%;
      max-width: 30px;
      align-items: center; /* Mostrar solo en dispositivos móviles */
    }
    .mobile-menu-button:hover .nav-list{
      display: block;
    }
  .navbar{
    max-width: 100%;
    width: 100%;
  }
  .item-lista{
    margin:0px;
    padding: 0px;
    font-size: 16px;
  }
 .navbar-brand{
  max-width: 60%;
  width: 40%;
 }
    .nav-list {
      display: none; /* Ocultar por defecto en dispositivos móviles */
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px; /* Ajusta según tu diseño */
      left: 0;
      background-color: #ffffff;
    }
  
    .nav-list.mobile-menu-open {
      display: block;
      justify-self:right;
      margin-left:150px;
      border-radius: 10px; 
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);      
      max-width: 50%;
      width:200px;
      z-index: 99;  
    }
    .nav-list.mobile-menu-open li{
    margin:0px;
  padding: 10px;}
    .nav-list.mobile-menu-open li:hover{
      
      background-color:rgb(109, 166, 204);}

    .deshorizontal {
      font-size: 16px;
      display: block;
    text-align: left;}

    .nav-list li {
      margin: 3px 0;
    }
    .item-lista{
      background-color:white ;
      color: var(--azuloscuro);
      border-radius: 0px;
      width: 100%;
    }
    .submenu{
      max-width: 70%;
    }
  }
  

 

  /* Estilos generales de la barra de navegación */
.navbar2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #0A3979;
  color: white;
  max-width: 100%;
  height: 5vh;
}

.navbar-brand2 {
  font-size: 1.5em;
  width: 50%; 
}

/* Estilos para dispositivos móviles */
.mobile-menu-button2 {
  display: none; /* Ocultar por defecto en pantallas grandes */
  font-size: 1.5em;
  background-color: #ffffff;
  cursor: pointer;
}

/* Mostrar la lista de navegación en dispositivos móviles */

/* Estilos específicos para dispositivos móviles */
@media (max-width: 1200px) {
  .navbar-brand{
    width: 35%;
  }
  .mobile-menu-button{
    width: 40%;
  }
}
@media (max-width: 768px) {
  .mobile-menu-button2 {
    display: flex;
    align-items: center; /* Mostrar solo en dispositivos móviles */
  }

  .nav-list {
    
    top: 60px; /* Ajusta según tu diseño */
    left: 10%;
    top:55px;
  }
.navbar-brand{
  width:50%
}
  .nav-list2.mobile-menu-open {
    display: flex;
  }
  .submenu{
    left:-70%;
    top:-10%;
  }
   .navbar-brand_profiles {
    font-size: 1.5em;
    width: 70%; 
  }

}
