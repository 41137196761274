@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.login-page {
  width: 360px;
  padding:0%;
  margin: 0%;
  align-content: center;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 100%;
  margin:0px;
  padding: 5px 20px 20px 25px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form .modal-header{
    padding: 10px 5px 15px 5px;
}
.selector-ovalado{
  width: 200px;
  height: 25px;
  border-radius: 12px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgb(240, 240, 240) 25%, rgb(219, 219, 219) 100%);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  font-size: 14px;
  align-content: center;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.selector-ovalado2{
  width: 200px;
  height: 25px;
  border-radius: 20px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgb(240, 240, 240) 25%, rgb(219, 219, 219) 100%);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  font-size: 14px;
  align-content: center;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.selected_edit{
  width: 200px;
  height: 25px;
  border-radius: 12px;
  background: rgb(81, 144, 221);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  font-size: 14px;
  align-content: center;
  margin: 0 auto;
  cursor: default;
  margin-top: 10px;
  margin-bottom: 10px;
 
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 12px;
  overflow: scroll;
}

.button-login {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: var(--naranja);
  width: 100%;
  border: 0;
  border-radius: 10px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: default;
}
.fullscreenbut{
  max-width: 30%;
  font-size: 12px;
  align-content: center;
  height: 30px;
  padding: 0;
}
.form button:hover,.form button::selection {
  background: rgb(46, 101, 138);
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: rgb(46, 101, 138);
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.multicheck{
  font-size: 10px;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}

.epimghead{
  max-width: 50%;
  border-radius:50%;
  max-height:50%;

  height:100%;
  width:100%;

  margin-left: 0px;
   
}
.cameraperfil{
  position:absolute;
  top:82% 
}

@media (max-width: 1200px) {

  .perfilcontenedor{
    width: 95%;
  }
}
@media (max-width: 500px) {
.fullscreenbut{
  max-width: 100%
}
  .cameraperfil{
    top:82%;
  }
}


@media (max-width: 370px) {

  .cameraperfil{
    top:82%;
  }
  .fullscreenbut{
    max-width: 100%;
  }
}
