.numbershome{
 min-width: 50px;
 text-align: center;
 width: 75%;
    
 padding: 2%;
 margin: 2% auto;
 border-radius: 15px;
 border: 0.2px ridge var(--celeste);
 background: white;
 height: 28vh;
 box-shadow: 5px 5px 30px rgb(146, 164, 185);
}
.estadistcnumberhome{
   font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
    font-size: 7em;
    margin: 0;
    padding: -10px;
    color: var(--celeste);
}
.descriptionnumbershome{
    text-align: center;
     font-family: "Nunito", sans-serif;
    
      font-size: 135%;
      line-height: 1em;
  color: var(--azulgris);
}
.homecontedor{
   max-width: 100%;
   padding: 15px;
     margin: 0 auto;
/* background: linear-gradient(45deg,var(--celeste) 0%,#acd1e6 100%); */
position: relative;



}

.rombotexover{
    background-color: var(--azulgris);
    clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);
    margin: 0 auto;
    color: white;
    z-index: 99;
    font-size: 18px;
    margin: 0 auto;
    margin:5% auto;
    min-width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    

}
.bulletsover{
    margin: 0 auto;
    min-width: 100%;
    
    display: block;

}
.secrtionrectanglehome{
 width: 75%;  
 max-width: 900px; 
 margin:0 auto;
}
.ovalohome{
    height: 50px;
    width: 150px;
    border-radius: 45%;
/* background: rgb(238,174,202);
background: radial-gradient(circle, rgba(238,174,202,1) 78%, rgba(148,187,233,1) 100%); */
background:var(--celeste);
background: radial-gradient(circle,var(--celeste) 15%,var(--celesteclaro) 50%);
   color:white;
    font-weight: 600;
}
@media only screen and (max-width: 650px) {

.estadistcnumberhome{

    font-size: 4.5em;

}
.rombotexover{
    font-size: 16px;
width: 80%;}


.descriptionnumbershome{
    font-size: 100%;
}

.secrtionrectanglehome{
 max-width: 80%;   
 margin:0 auto;
}
.numbershome{
    height:10em;
}

}

@media only screen and (max-width: 455px) {

    .homecontedor{
        min-width: 100%;
    }
   
}