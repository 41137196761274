.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  text-align: left;
}



.gallery-item {
  margin:5px;
  margin-bottom: 5px;

  overflow: hidden;
}

.gallery-img {
  display: block;
  width: 100%;
  border: 1px solid #fff;
  transition: transform 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.gallery-img:hover {
  transform: scale(1.05);
  border-color: #fff;
}

/* Modal Styles */
.modal-img-container {
  position: relative;
  text-align: center;
  
}


/* Para el modal */
.modal-img-container {
  position: relative;
  text-align: center;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: rgb(255, 255, 255);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.modal-img {
  max-width: 100%;
  height: auto;
}

/* Botón de cerrar */
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.gallery-item {
  display: inline-block;
  
}

.gallery-img {
  width: 100px;  /* Tamaño fijo de 50x50 */
  
  object-fit: cover;  /* Asegura que la imagen se recorte y mantenga la proporción */
  cursor: pointer;
  transition: transform 0.3s ease;
}

@media only screen and (max-width: 500px) {
.gallery-img {
  max-width: 95px;  /* Tamaño fijo de 50x50 */
  min-width: 80px;
  object-fit: cover;  /* Asegura que la imagen se recorte y mantenga la proporción */
  cursor: pointer;
  transition: transform 0.3s ease;
}
.modal-img-container {
  position: relative;
  text-align: center;
  top:45%
  
}
.gallery-item {
  display: inline-block;
  margin: 3px;
}
.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

}