/* Contenedor principal del chat */
.chat-input {
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    background-color: rgb(238, 238, 238);
    padding: 10px;
   
    z-index: 1000; /* Sobre cualquier otro elemento */
    max-width: 100%;
    border: 1px solid #ccc
}

/* Input del chat */
.chat-input input {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 10px 40px 10px 15px;
    outline: none;
    font-size: 14px;
    box-sizing: border-box;
}

/* Botón de enviar */
.chat-input button {
   
    right: 15px;
    top: 90%;
    margin-left: 3px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.buttonchatexpanded{
     top: 89.5%;
}

.chat-input button:hover {
    background-color: #0056b3;
}


.ChatboxAI{
max-width:30%;
min-width: 30%;
  left: "69%",


}
@media (max-width: 910px) {

    .ChatboxAI{
max-width:35%;
min-width: 35%;
  left: "64%",
 
}}

@media (max-width: 770px) {

    .ChatboxAI{
max-width:40%;
min-width: 40%;
  left: "59%",
 
}}
@media (max-width: 650px) {

    .ChatboxAI{
max-width:90%;
min-width: 90%;
  left: "9%",
 
}
}