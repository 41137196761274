.centerin2{
    width: 80%; 
    max-width: 80%;/* Porcentaje del ancho del contenedor */
    padding: 0px;
    margin-left: 10%; 
    margin-right: 10%; /* Centra el contenedor */
  }
  .sectionus {
    max-width: 100%; /* Hace que la sección ocupe el ancho completo */
    margin: 0px 25px 0px 25px;
    display: flex;
    padding: 0px;
    background-color: #fff;
    box-shadow: 2000px  2000px 2000px rgba(126, 167, 214, 0.185);
    /* Hace que
     la sección ocupe el ancho completo box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    }
    .sectionus .fuenteh22{
      text-align: center;
      padding: 0%;
      margin: 1%;
    }
    .article-us{
      display:column;
      margin:2%;
      width: 50%;
      max-width:70%;
      
      box-shadow:0 0 50px 50px 0 rgba(126, 167, 214, 0.089);
      
      border-radius: 5px; /* Bordes redondeados */
    }
    .article-us .fuente2{
      font-size: 17px;
    }
    .article-us .fuenteh22{
      
      text-align: center;
      margin: 0px;
    }
   .artus{
    display: flex;
    margin:3%
   }
  .imgframe{
    margin:0px;
    padding: 0px;
    width: fit-content;
    position: relative;
    top:0%;
    min-width: 100px;
    min-height: 100px;
    width:inherit;
  } 
.dots-us{ 
  width:19%;
   margin-bottom:3%; 
  position: absolute;
  left: 3%;
  top:60%  
}
.img-circular-us {
  width: 70%; /* Puedes ajustar el tamaño según tus necesidades */
max-width: 180px;
  height: auto;
 overflow: hidden;   
 border-radius: 50%; 
 position: absolute;
 left: 22%;/* Establece el radio para crear el marco redondo */ 
  top: 10%;
}
.textframe-us{
  text-align: center;
   width: 90%;
   position:relative; 
   top:8em; 
   left:7%;
}
.textframe-us p{
  max-width: 95%;
  margin: 10%;
  text-align: center;
}
.profileus{
  text-align: center;
    box-shadow: 0 25px 25px  rgba(126, 167, 214, 0.185);
    max-width:25%;
    min-height:420px;
  
}

.ver{
  display: none;
}

@media (max-width: 1368px) {

  .textframe-us{
    top:6em;  
  }

}

@media (max-width: 1270px) {

  .textframe-us{
    top:5em;  
  }

}



@media (max-width: 1050px) {
.sectionus{
  display: block;
  width: 95%;
  margin:0px;
}
.textframe-us h2{
  font-size: 18px;
}

.article-us{
  max-width: 95%;
  width: 100%;
}
.article-us .fuenteh22{
  text-align: left;
  font-size: 130%;
  
}
.sectionus .fuente2{  
width: 100%;
font-size: 17px;
max-width: 100%;
}


}

@media (max-width: 950px) {
  .textframe-us{
    top:3em; 
    
  }
  .textframe-us p{
    font-size: 14px
  }
  .textframe-us h2{
    font-size: 16px;
  }
  .imgframe{
    min-height: 100px;
  } 
.dots-us{
  top:50%
}
.sectionus .fuenteh22{
  text-align: left;
  font-size: 25px;
  
}
}


  @media (max-width: 777px) {
    .sectionus .fuente2{
      width: 100%;
      font-size: 14px;
      font-style: normal;
      max-width: 100%;
      }


  }

  @media (max-width: 650px) {
    .artus{
      display: block;
      justify-content: left;
    }
    .sectionus{
      box-shadow: 800px  800px 800px rgba(126, 167, 214, 0.185);
      margin:3%;
    }
    
.dots-us{
  top:30%;
}
.profileus{
  text-align: left;
  max-width: 100%;
  display: flex;
  min-height:auto;
  margin:3%;
  box-shadow: 0 3px 3px  rgba(126, 167, 214, 0.185);
}
.img-circular-us{
  max-width: 65%;
  width: auto;
  height: auto;
  min-height: 0%;
  
}

.dots-us{
  max-width: 15%;
  
}
.textframe-us{
  text-align: left;
  font-size: 12px;
  top:0%;
  left:0%;
}
.textframe-us p{
  margin: 1px;
  font-size: 12px;
  text-align: left;
}

.ver
{display: block;text-align: left;margin: 0%;margin-left: 2%;}
  }