.profi{
    height: 30px;
    background-color: #8299bc;
    align-content: center; 
    align-items: center;
      align-self: center;
      text-align: center;
    /* font-size: 10px; */
    
}
.btn{
   font-size: 12px;
}
 .showedvalor{
    display: block;
 }   
 .notshowed{
    display: none;
 } 
 .atentionplace{
   height: 55px;
   text-align: left;
   overflow: hidden;
 }
  .atentionplace-expanded{
   height: auto;
   overflow: hidden;
 }

 .encabezadospersonal .profi{
   padding: 2px;
   background-color: #8299bc;
   height: 18px;
   font-size: 8.5px;
   align-content: center; 
   align-items: center;
     align-self: center;
     text-align: center;
 }

.encabezadospersonal  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
   background-color: #58ade5;
}
 .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
    background-color: #ffb013;
 }
 .subcostos{
    display: flex;
 }
 .costos{
   color:black;
   margin-top:3%;
   margin-bottom: 5%;
   justify-content: center;
   align-items: center;
   justify-items: center;
   align-content: center;
   text-align: center;
 }

 @media (max-width: 650px) {

  .atentionplace p{
    font-size: 14px;
  }
}