:root{
  --yellow2: #264c66
}
.sectionbuton{
    width: 90%;
    justify-items: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
  align-content: flex-end;
  position: fixed;
  left: 80%;
  top: 85%;
  background-color: transparent;
  z-index: 999999;

  }
  .botoncontacto{
    height: 65px;
    width: 65px;
    border-radius: 50%;
    border: 0px;
  
  }
  .fa-comment{
    color: var(--celesteclaro);
    font-size: 25px;
  }
  .Chatcontacto{
    background-color: transparent;
    height:300px;
    width:300px;
    padding: 0%;
    display: none;
    position: absolute;
    top:-275%;
    left:-20%;
    background-color: #fff;
    border-radius: 7% 7% 2% 2%;
  }
 .chatopen{
   display: block;
  }
.contact{
    padding: 0% 0% 0% 0%;
    height: 100%;
    margin: 0%;
    border-radius: 7% 7% 2% 2%;
    position: static;
    
}
.form input{
    font-size: 13px;
    padding: 10px;
    overflow: scroll;
}
.mensaje-contact{
    height: 60px;
    min-height: 60px;
}
.button-contact{
    background-color: #ffb013;
}
.fa-paper-plane{
    color: #fff;
}
.titlecontact{
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    padding: 0%;
height: 25px;
width: fit-content;
color: #fff;
margin-right: 70%;
}

.caja-contacto{
    font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  resize: none;
}

@media only screen and (max-width: 768px) {
    .Chatcontacto{
    top:0,
    }
}
