.selected-hour {
  background-color: #ffb013; /* Azul */
  color: #ffffff; /* Blanco */
}

.default-hour {
  background-color: var(--naranja);
  text-align: center;
  color: #ffffff; /* Negro */
}
.dayweekbd_wksp{
  padding: 0%;
  z-index: 99;
}
.profile_esp {
  display: flex;
  align-content: space-between;
  justify-items: center;
}
.container_week {
  margin: 0 auto;
  min-width: 98%;
}
.dayweek {
  background: linear-gradient(
    #e8f0f9,
    #fdfdfd,
    #fdfdfd,
    #fdfdfd,
    #fdfdfd,
    #fdfdfd,
    #fdfdfd,
    #e8f0f9,
    #e8f0f9
  ) !important;
  box-shadow: 0px 2px 2px #c8ddeb;
  border-radius: 5px;
}
.dayweek {
  border: 0px;
  min-width: 9.8em;
  max-width: 9.8em;
  width:9.8em;
  font-size: 12px;
}
.servicebox{
  min-width: 40em;
}
.dayweekbd{
  padding-top: 3px;
  padding-bottom: 3px;
}
.wktitl {
  font-size: 12px;
  text-align: center;
  
  font-weight: 500;
  min-width: 90%;
  margin: 0px 0px 3px 0px;
  padding: 10px;
  color: var(--azulgris);
  font-weight: 600;
  background-color: var(--celeste);
  border-radius: 5px 5px 0px 0px;
}

@media only screen and (max-width: 835px) {
  .dayweek {
  border: 0px;
  min-width: 8.5em;
  max-width: 8.5em;
  width:8em;
  
}}
@media only screen and (max-width: 525px) {
  .dayweek {
  border: 0px;
  min-width: 7em;
  max-width: 7em;
  width:7em;
  
}}
@media only screen and (max-width: 500px) {
  .dayweek {
  border: 0px;
  min-width: 7em;
  max-width: 7em;
  width:7em;
  
}
.servicebox{
  min-width: 20em;
}
}
@media only screen and (max-width: 475px) {
 .dayweek{
    width: 6em;
    max-width: 6em;
    min-width: 6em;
  }

}
@media only screen and (max-width: 450px) {
  .selected-hour,
  .default-hour {
    max-width: 100%;
    width: 100%;
    text-align: center;
    font-size: 12px;
  } 
  .dayweek {
    width: 20%;
    margin: 0%;
    margin-left: 0% !important;
    margin-right: 0% !important;
    
  }

  .calendarcontainer {
    max-width: 350x;
  }
  .dayweekbd {
    padding: 0px;
    margin: 0.5%;
  }
}

@media only screen and (max-width: 415px) {
    .dayweek{
    width: 5.8em;
    max-width: 5.8em;
    min-width: 5.8em;
  }

}

@media only screen and (max-width: 400px) {
    .dayweek{
    width: 5.6em;
    max-width: 5.6em;
    min-width: 5.6em;
  }

}