:root {
  --black: #333;
  --white: #fff;
  --gray: #aaa;
  --gristexto2: #686868;
  --gristexto: #8e8e8e;
  --grisfondo: #f7f7f7;
  --turqgris: #6B91A5;
  /* --turqueza2:#7fa6d8;
  --naranja1:#FFA629;
  --naranja2:#FFC97D;
  --celestelogo:#b3f4ff; */

  /* --yellow: #ffb013;
  --lightyellow:#ffcb4a; */
  --yellow: #00AB9F;
  --turqueza:#0080AB;
  
  --lightyellow:#00AB9F;
  /* --yellow:#67a5c9;  */
  /* --azulpetroleo: #2e658a; */

    --azuloscuro: #154480;
      --azuloscuro2: #4870a6;
  --azulpetroleo: #1D3556;
  --azulpetroleo: #5e5e5e;
  /* --azulpetroleo: #4d4d4d; */
  /* --celeste:#558fdb; */
  /* --celeste2:#00A4AB; */
  /* --celeste2:#6baea2; */
  /* --celeste2:#43b9bd;
  --turqueza:#4e8892;
  --azule:#448ab3; 
  --azul2:#0049AB; */
  
  --border-radius: 4px;
  --transition: 0.4s ease;
  /* Paleta de colores vincularse */
--azulgris: #3d5a80;
--celeste: #98c1d8;
--celesteclaro: #e0fbfb;
--naranja: #ee6c4d;
--colordeprueba: #fcefe4e1;
--naranjaclaro: #ee7d4d;
--celestefuerte: #00d9ff;
--celestedestacado:#cee9f3 ;
--celestedestacado:#fbfbfb ;
--azulnegro: #293241;
}
.body{
  background-color: var(--gristexto);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url(https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.2.0/css/all.min.css);
@import url("https://fonts.cdnfonts.com/css/phetsarath" );

@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yantramanav&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Fuentes */
h1{
  font-family: 'Yusei Magic', sans-serif;
}
h2{
  /*font-family: 'Yusei Magic', sans-serif;*/
  font-family: 'Libre Franklin', sans-serif;
  font-weight: bolder;
  margin: 5px;
  font-size: large;
  color: var(--azulgris)
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: black;
  /* width: $select-width;
  height: $select-height; */
}


.notfound{

  background: rgb(164,249,255);
  background: linear-gradient(90deg, rgba(164,249,255,1) 0%, rgba(148,238,252,1) 25%, rgba(132,227,242,1) 100%);
height:100vh;
margin: 0%;
display: flex;
}

p{
  font-family: 'Nunito', sans-serif;
  margin: 5px;
  color: var(--azulnegro)
}
.fuentenotfound {
  font-family: 'paytone one', serif;
  font-size: 45px;
  max-width: 50%;

  text-align: left;
  

  margin-top: 10%;
  margin-left: 7%;
  color: rgba(152, 35, 35, 0.83);
    
}

.fuente1 {
  font-family: 'Poppins', serif;
  font-size: 45px;
  font-weight: 500; 
  max-width: 100%;
  justify-content: left;
  color: var(--celeste);
text-align: left;    
}
.fuente1::after{
content: " click de distancia";
color: var(--azulgris);
}

.fuente1-1 {
  font-family: 'Nunito', serif;
  font-size: 50px;
  font-weight: 600; 
 
  max-width: 100%;
  justify-content: left;
  color: var(--azulpetroleo);
text-align: left;    
}
.fuente2 {
  font-family: 'Phetsarath', sans-serif;
  margin: 10px;
  padding: 10px;
  font-size: 120%;
  color: var(--azulpetroleo);
}
.fuenteh1{font-family: 'Phetsarath', sans-serif;
margin: 20px;
padding: 10px;
font-size: 3em;
color: var(--azulpetroleo);
}
.fuenteh2{font-family: 'Phetsarath', sans-serif;
margin: 20px;
padding: 10px;
font-size: 2.5em;
color: var(--azulpetroleo);   
}
.fuenteh22{font-family: 'Yusei Magic', sans-serif;
margin: 20px;
padding: 10px;
font-size:35px;
color: var(--azulpetroleo)   
}
.fuenteh21{font-family: 'Phetsarath', sans-serif;
margin: 20px;
padding: 10px;
font-size: 2.2em;
color: var(--azulpetroleo)    
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}


body {
  font-family: 'Arial', 'Paytone One', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffff;
}



 /* Del home */

.div-izquierdo {
   
    width: 50%; 
}

.div-derecho {
    position: absolute;
    top: -5%;
    /* top: 0px; */
    right: -5%;
    width: 50%; /* O ajusta según tus necesidades */
}


/* Limpia el float para evitar problemas de diseño */
a {
    color:#2e658a;
    text-decoration: none; /* Quita el subrayado por defecto */
}

/* Estilo para enlaces visitados */
a:visited {
    color: var(--azuloscuro)
}

/* Estilo para enlaces al pasar el mouse sobre ellos */
a:hover {
    color:rgb(255, 255, 255);
}


.imglandcontainer{
  position: relative;
  width: 100%;
  height: 70vh;
  
  min-width:  30vw;
}
.textcontainerimage{
  position: absolute;
  top:55%;
  left:40%;

}
.containerh {
  width: 50%; /* Porcentaje del ancho del contenedor */
  max-width: 500px;
  margin-left: 5%;
  margin: 0 auto; /* Centra el contenedor */
  text-align: left;
  
  position: relative;
  top: 0px;
}

img {
  max-width: 100%; /* Imágenes responsivas */
  height: auto;
}

.subsection{
    padding-left: 30px;
    display: flex;
    width: 100%;
    
    
}
.clearfix::after {
  content: "";
  display: table;

}
.elemento{
  min-height: 70vh;
  margin-top: 0px;

}

.Backspace{
   background: rgb(202,236,255);
background: linear-gradient(0deg, rgba(255, 247, 234, 0.604) 0%, rgba(252, 240, 226, 0.288) 5%, rgba(255,255,255,1) 100%);

} 


.centerin{
    width: 70%; /* Porcentaje del ancho del contenedor */
    padding: 0;
    max-width: 1300px;
    margin: 0 auto; /* Centra el contenedor */
    position: relative;
    top: 0;
    
    
}

    section {
        max-width: 100%; /* Hace que la sección ocupe el ancho completo */
        margin: 20px;
        display: block;
        padding: 20px;
        
        /* Hace que la sección ocupe el ancho completo box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
        }
       
        .divboton{ display: flex;
          
          padding-right: 1em;
          padding-left: 1em; 
          margin-Left: 2em;
          margin-Right: 2em; 
          justify-content: space-around;
        }
    .imghead {
        margin-left: 100px;
        width: 50%; /* Establece el ancho deseado */
        height: auto; /* Establece la altura deseada */
        }
    .boton-ovalado {
        font-family: "Nunito", sans-serif;
        text-transform: uppercase;
        padding: 15px;
        border: none;
        outline: 0;
        border-radius: 20px;
        background: var(--naranja);
        color:#fff;
        justify-content: center;
        width: 150px; /* Establece el ancho deseado */
        height: 45px; /* Establece la altura deseada */
        font-size:11.5px;
        cursor: pointer;
        box-shadow: 2px 2px 2px #c8ddeb;
        transition: all 0.3 ease;
        -webkit-transition: all 0.3 ease;
        }
        .help{
          background: var(--gray);
        }
        .boton-ovalado:hover {
          background-color: rgb(46, 101, 138);
          box-shadow: 1px 1px 1px rgb(180, 180, 180)
          }
          .boton-ovalado:disabled {
            opacity: 0.9;
            cursor: not-allowed;
          }
         

        .caja-texto {
          display: flex;
          height: 100px ;
          flex-wrap: wrap;
          max-width: 90%;
          width: 90%;
          margin: 8% auto;
          border: 0px;
          background: var(--white);
          border-radius: var(--border-radius);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          padding: 14px;
          transition: min-height var(--transition);
          max-height: 200px;
          resize: none;
           
       }
       .artichome{
        margin:0%;
        padding: 0%;
        max-width: 95%;
        width: 100%;
        justify-content: center ;
        align-content: center;
       }
    .img-circular {
       width: 250px; /* Puedes ajustar el tamaño según tus necesidades */
    height: auto;
      overflow: hidden;   
      border-radius: 50%; /* Establece el radio para crear el marco redondo */ 
    }
 
  .imglnd{
 
    position: absolute;
    top:0%;
    left:0%;
  
       margin:5%;
      
  }
  .container-exp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }


  /* modal */
 
  .modal-content{ 
    width: 450px;
    height: 450px;
  }
.modal fade{
  z-index: 999999;
}
.loader-container{
  z-index: 99999;
  padding: 20px;
  background-color:#fffefe ;
  position: absolute;
  top:50%;
  left:30%;
  height: 120px;
border-radius: 20px;
box-shadow: 0 25px 25px 10px  rgba(126, 167, 214, 0.241);
border:4px solid var(--celeste);

}
@media  (max-width: 1050px)
{ 
  .fuentenotfound{
    margin-left: 15%;
    font-size: 35px;
  }
}
  

/* responsivo */
  @media (max-width: 800px) {
    .texto,
    .div-derecho {
        /* Desactiva el float en dispositivos móviles */
        width: 50%;
         /* Establece el ancho al 100% en dispositivos móviles */
        
    }
    .fuentenotfound{
      margin-left: 15%;
      font-size: 25px;
    }
    
    .imghead {
      margin-left: 0px;
    /* Establece la altura deseada */
      }

    .div-izquierdo {
   /* Desactiva el float en dispositivos móviles */
        width: 90%; /* Establece el ancho al 100% en dispositivos móviles */
      
    }
    
/*  
    .fuente2{
      max-width: 55%;
      margin: 0;
      text-align: left;
      font-size: 14px; 
      
    } */
    .subsection{
      margin:0;
      margin-top: 0%;
      padding:0;
      display: block;
      text-align: center;
      
    }
    /* .artichome{
      
      top:130%;
      
      max-width: 50%;
      
    } */
    .fuente2{
      font-size: 14px;
    }
    .div-derecho{
      position: absolute;
      left:50%;
      top: 15%
    }
     .imglnd{
    width:80%;
       margin-top:15%;
       margin-left: 1%;
       margin-right: 1%;
  }
}
  @media only screen and (max-width: 768px) {
    .lista-textos li {
        text-align: center;
    }
      .textleftland{
    margin-top: 20px;
  }
    .boton-ovalado {
      width: 25vw; /* Establece el ancho deseado */
      height: 80%;
      font-size: 70%; 
    }
    .centerin{
      width: 94%;
    }

 

    .lista-textos li::before {
        content: '\2022'; /* Unicode para un punto de lista */
        margin-right: 10px;
    }
    
    .lista-textos li:hover {
        cursor: pointer;
        color: rgb(255, 255, 255); /* Cambia el color al pasar el ratón (opcional) */
    }
    .fuente1 {
      margin: 0;
      font-size: 35px;
    
      
  }

  .cajaexpanded{
    width: 100%; /* O cualquier tamaño deseado */
    height: 350px; /* O cualquier tamaño deseado */
    position: static;
     /* Para habilitar desplazamiento vertical si es necesario */
     z-index: 9999999;
  }
  .imghead {
    
    width: 100%; /* Establece el ancho deseado */
    height: auto; /* Establece la altura deseada */
    }
  .fuente2 {
      font-family: 'Phetsarath', sans-serif;
      margin: 3px;
      padding: 3px;
      font-size: 1em;
      color:var(--azulpetroleo)
  }

  
  
  .fuente1{
    width: 100%;
    max-width: fit-content;
  }

  section {
    margin: 0;
    padding: 0;
    }

    .divboton{ 
      padding-right: 0;
      padding-left: 0; 
      margin-Left: 0;
      margin-Right: 0; 
    }

    

}
@media only screen and (max-width: 455px) {.fuente1{font-size: 30px; background-color: var(--celeste);}}
@media only screen and (max-width: 455px) {
  .centerin{
    margin: 0 auto;
    padding: 0%;
    min-width: 100%;
    position:static
  }
  .imglandcontainer{
    position: static;
    padding-top: 20px;
    margin-bottom: 15px;
    height: auto;

  }
  .subsection{margin-top: 0;}
  .textcontainerimage{
    position: static;
  }
  .fuente1{
    color:white;
    background-color: var(--celeste);
    width: 100%;
    min-width:100%;
    margin: 0;
font-size: 25px;
    padding: 6px;
    padding-left: 5%;
  
  }

  .elemento{
  min-height: 68vh;
}
  .modal-content{
    width: 345px;
    height: 500px;
    position:relative;
    left:5%;
    top: 10%;
  }
    .artichome{
      
      top:110%;
      left:0%;

      min-width: 100vw;
    }
    .divboton{ display: block;
      padding-right: 1em;
      padding-left: 1em; 
      margin-Left: 1em;
      margin-Right: 2em; 
    }
    .imglnd{
      margin-top: 0%;
      width: 70%;
      display: none;
    }
    .boton-ovalado {
      width: 80%; /* Establece el ancho deseado */
      margin: 0 auto;
      height: 50px;
      font-size: 11px; 
      margin-left: 10%;
      margin-top: 5%;
    }
    
  
} 
@media only screen and (max-width: 455px) {
  

.expandedmobile{
  min-height: 110vh;
}


  .div-izquierdo{
    max-width: 100%;
    width: 100%;
    
  }
  .fuente2{
    max-width: 100%;
    width: 96%;
    font-family: 'Nunito', sans-serif;
    margin: 0 auto;
    text-align: center;
  }
  .textleftland{
    margin-top: 20px;
  }
  .modal-content{
    width: 345px;
    height: 500px;
    position:relative;
    left:2%;
    top: 10%;
  }
      .elemento{
  min-height: 78vh;
}
.loader-container{
  left: 25%;
  width: 50%;
}
}

  @media only screen and (max-height: 920px) {
      .elemento{
  min-height: 75vh;
}
}

  @media only screen and (max-height: 800px) {
      .elemento{
  min-height: 78vh;
}

}
  @media only screen and (max-height: 705px) {
      .elemento{
  min-height: 90vh;
}

}


  @media only screen and (max-height: 500px) {


}