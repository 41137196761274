.modal-header{
    border:0px; 
}
.modal-content{
    height: auto;
}


@media (max-width: 370px) {

    .modal-content{
        width: 90%;
        margin: 0 auto;
    }
  }
